import { useEffect } from "react";
import { defineMessages } from "react-intl";

import APPS from "constants/applications";
import { Tier } from "graphql_globals";

const PROOF_DOCUMENT_TITLES = defineMessages({
  default: {
    id: "f03d6a5b-7504-4b42-a755-c3457600f9e8",
    defaultMessage: "Proof",
  },
  // guest
  guestSignupNotarization: {
    id: "90f04fd7-b3a6-41d6-8c5a-2ad6a2b0c8ac",
    defaultMessage: "Upload a document to notarize - Proof",
  },
  guestSignupEsign: {
    id: "bf431bc0-d298-4ec5-9e4c-6998f534279c",
    defaultMessage: "Upload a document to eSign - Proof",
  },
  resetPassword: {
    id: "46ca25cf-cb34-47d9-acc1-44ed8f3502c0",
    defaultMessage: "Reset password - Proof",
  },
  signIn: {
    id: "5e563132-9b1f-48b7-ac1d-76be618e85d0",
    defaultMessage: "Sign in - Proof",
  },
  signInOrSignUp: {
    id: "85df5d13-41f2-43c6-944c-6ce7a83ffe09",
    defaultMessage: "Sign in or Sign Up - Proof",
  },

  // sign up
  [Tier.ELITE]: {
    id: "cb33a8a1-7728-4993-992d-672d3a7491fd",
    defaultMessage: "Premium Plan - Proof",
  },
  [Tier.PRO]: {
    id: "802bb211-08e5-48af-8bde-18d1d187226f",
    defaultMessage: "Pro Plan - Proof",
  },
  [Tier.PRO_TRIAL]: {
    id: "6d5175e0-9dbe-4e4a-8e1a-515c2619e224",
    defaultMessage: "Pro Trial - Proof",
  },
  [Tier.BASIC]: {
    id: "b1c46cb0-3024-47aa-9a1b-8fd7818be475",
    defaultMessage: "Starter Plan - Proof",
  },
  signUpFallback: {
    id: "c6095c18-3c8b-463f-87bd-3b19011b77f8",
    defaultMessage: "Sign up - Proof",
  },
  // upload documents
  uploadDocumentsForNotarization: {
    id: "9006d1ba-63f6-4762-a5df-7711d7824ee7",
    defaultMessage: "Upload documents for notarization - Proof",
  },
  uploadDocumentsForEsign: {
    id: "9baadc2b-f912-4592-8fdd-5bc9dd8845fb",
    defaultMessage: "Upload documents for eSign - Proof",
  },
  browserNotSupported: {
    id: "2f8b42e9-183b-4315-898a-f170dbc316f3",
    defaultMessage: "Browser not supported - Proof",
  },
  // signer dashboard
  getStarted: {
    id: "8d66d787-6b5b-4f1a-a56c-fc7562788a99",
    defaultMessage: "Get started - Proof",
  },
  signerDashboard: {
    id: "099a841f-be83-4d9a-afce-6b5db124d7b4",
    defaultMessage: "Signer Dashboard - Proof",
  },
  signerViewDocument: {
    id: "50d00f96-bc6d-4532-8a37-399ec6487646",
    defaultMessage: "View Document - Proof",
  },
  deleteDocument: {
    id: "096f94e6-d73c-44dc-b558-2a1cba8fd9a2",
    defaultMessage: "Delete document - Proof",
  },
  // signer flow
  preFillInstructionsSignerSlide: {
    id: "b7db9e30-365c-4905-9019-18d3fa758bc7",
    defaultMessage: "Signer full name - Prepare Documents - Proof",
  },
  primarySignerDetails: {
    id: "d96a81ad-84dd-4b1f-a073-00c7c8435f80",
    defaultMessage: "Enter your full name - Prepare Documents - Proof",
  },
  requiredSignersTitle: {
    id: "821bcaef-7662-4d71-b136-14c8042f201f",
    defaultMessage: "Required signers - Prepare Documents - Proof",
  },
  requiredNotTogetherTitle: {
    id: "bedad783-7975-4aad-8b3e-19836a4ae2bb",
    defaultMessage: "Not with required signers - Prepare Documents - Proof",
  },
  multipleSignersTitle: {
    id: "0b668fcc-8179-48bc-adb7-28ef73696cf7",
    defaultMessage: "Who is joining the meeting? - Prepare Documents - Proof",
  },
  twoSignersTitle: {
    id: "edd2278f-8ec3-40cd-ba38-f23439e82276",
    defaultMessage: "Are the two signers joining the meeting? - Prepare Documents - Proof",
  },
  // esign
  bundlePrefillEsign: {
    id: "1bf730a1-05b2-464b-abc0-9814b84c4c00",
    defaultMessage: "Preview document - Prepare Documents - Proof",
  },
  bundlePrefillEditSign: {
    id: "ea662c96-054b-4baa-a3ef-4eadd8569dad",
    defaultMessage: "Edit and sign document - Prepare Documents - Proof",
  },
  bundlePrefillReviewSign: {
    id: "1616d2e2-2c5f-4b46-92e8-37d074e41f54",
    defaultMessage: "Review and sign document - Prepare Documents - Proof",
  },
  bundlePrefillEditReview: {
    id: "7775e1c4-a224-4565-8eb0-39faf71a9903",
    defaultMessage: "Edit and review document - Prepare Documents - Proof",
  },
  bundlePrefillReviewDocument: {
    id: "62952542-8655-4e03-ac49-3b75c74a7253",
    defaultMessage: "Review document - Prepare Documents - Proof",
  },
  confirmSignature: {
    id: "09e832d9-c3bd-43cf-9920-3433f821c443",
    defaultMessage: "Confirm signature - Prepare Documents - Proof",
  },
  confirmInitials: {
    id: "f6a282f0-aa2b-4980-8965-6e89770b3842",
    defaultMessage: "Confirm initials - Prepare Documents - Proof",
  },
  signerEsignCompleted: {
    id: "7c39969e-9e95-46fb-a79d-35c1ad4c109c",
    defaultMessage: "You've finished signing - Proof",
  },
  // tech check
  techCheckSetup: {
    id: "2baa4b01-5133-4476-bdea-a0fda956fa02",
    defaultMessage: "Set up audio and video - Proof",
  },
  // customer details
  preMeetingCustomerDetailsIntro: {
    id: "b228fbef-a5e6-4c02-b291-b71f94fe4992",
    defaultMessage: "Next steps for ID verification - Prepare Documents - Proof",
  },
  preMeetingCustomerDetailsPersonalDetails: {
    id: "55074f0f-9987-4425-b43d-ef2864d00d57",
    defaultMessage: "Enter your information - Prepare Documents - Proof",
  },
  preMeetingCustomerDetailsAddressDetails: {
    id: "015bf441-591b-4469-8fb3-ff30695631e6",
    defaultMessage: "Enter your address - Prepare Documents - Proof",
  },
  preMeetingCustomerDetailsCurrentLocationDetails: {
    id: "3d969c4b-9536-4f93-83fb-09c050e1e091",
    defaultMessage: "Enter your current physical location - Prepare Documents - Proof",
  },
  preMeetingIdentityQuestionsIntro: {
    id: "e971170c-5151-481a-8f58-f476abc46e9b",
    defaultMessage: "Identity questions intro - Prepare Documents - Proof",
  },
  preMeetingIdentityQuestionsQuiz: {
    id: "969fb03a-33fa-4fb9-a935-999b41b0565c",
    defaultMessage: "Identity questions quiz - Prepare Documents - Proof",
  },
  preMeetingIdentityQuestionsSuccess: {
    id: "cb51d8b3-8452-424b-8b70-21ebdf9c747a",
    defaultMessage: "Passed identity questions - Prepare Documents - Proof",
  },
  preMeetingIdentityQuestionsFailure: {
    id: "ac932527-1fa8-43e0-9b23-0d7068a3045c",
    defaultMessage: "Failed identity questions - Prepare Documents - Proof",
  },
  preMeetingIdentityQuestionsInfo: {
    id: "f76eda36-71d5-4d65-97af-342edb8d8714",
    defaultMessage: "Unable to generate identity questions - Prepare Documents - Proof",
  },
  preMeetingCredentialAnalysisIntro: {
    id: "e5e6db15-7903-45dd-ab16-97579721b638",
    defaultMessage: "Capture your ID - Prepare Documents - Proof",
  },
  preMeetingCredentialAnalysisSecondary: {
    id: "e07ca3fe-d2a2-4ca9-ac79-7bc7151dd1b2",
    defaultMessage: "Do you require a secondary ID? - Prepare Documents - Proof",
  },
  preMeetingCredentialAnalysisPhotoIdUpload: {
    id: "fb121709-f9f3-4bd9-91af-1a7836cb0794",
    defaultMessage: "Photo ID Upload - Prepare Documents - Proof",
  },
  preMeetingCredentialAnalysisCapture: {
    id: "9ccf1dab-d946-4031-bf87-6715c61be8e5",
    defaultMessage: "ID verification - Prepare Documents - Proof",
  },
  preMeetingCredentialAnalysisFailure: {
    id: "2812ac1e-40c0-4b10-bf09-4dc6fd5783b4",
    defaultMessage: "ID validation error - Prepare Documents - Proof",
  },
  preMeetingCredentialAnalysisActionNeeded: {
    id: "f69ab717-49c3-49f2-89e9-34adf1266a1e",
    defaultMessage:
      "ID validation error - Trusted referee meeting prompt - Prepare Documents - Proof",
  },
  preMeetingCredentialAnalysisSuccess: {
    id: "cf0d15c7-78cb-4215-8b36-f08621f8b346",
    defaultMessage: "ID validation success - Prepare Documents - Proof",
  },
  preMeetingCredentialAnalysisPhotoIdTypeSelection: {
    id: "aec6610c-93ab-44b3-bae3-9c44c2701e35",
    defaultMessage: "Photo ID type selection - Prepare Documents - Proof",
  },
  preMeetingCosignerCustomerDetailsIntro: {
    id: "cbb4e235-fc24-4aaf-afe9-2914e0298da4",
    defaultMessage: "Next steps for cosigner ID verification - Prepare Documents - Proof",
  },
  preMeetingCosignerCustomerDetailsPersonalDetails: {
    id: "3feb4562-395d-4663-b8dc-4a6692734845",
    defaultMessage: "Enter cosigner information - Prepare Documents - Proof",
  },
  preMeetingCosignerCustomerDetailsAddressDetails: {
    id: "aaf737db-c3a7-4e07-9798-77d02d1069de",
    defaultMessage: "Enter cosigner address - Prepare Documents - Proof",
  },
  preMeetingCosignerCustomerDetailsCurrentLocationDetails: {
    id: "3a9d5d61-dcec-4147-9e41-5b8c6bcfb088",
    defaultMessage: "Enter cosigner current physical location address - Prepare Documents - Proof",
  },
  preMeetingCosignerIdentityQuestionsIntro: {
    id: "f16cc2fc-350d-483a-92a1-f4d43affb10e",
    defaultMessage: "Cosigner identity questions intro - Prepare Documents - Proof",
  },
  preMeetingCosignerIdentityQuestionsQuiz: {
    id: "d1f2923a-81ae-4438-815e-2f91809a89e5",
    defaultMessage: "Cosigner identity questions quiz - Prepare Documents - Proof",
  },
  preMeetingCosignerIdentityQuestionsSuccess: {
    id: "e1b3db67-b58e-4cd7-96aa-f4fa593ff1f4",
    defaultMessage: "Passed cosigner identity questions - Prepare Documents - Proof",
  },
  preMeetingCosignerIdentityQuestionsFailure: {
    id: "e25ad648-1ee2-4169-a3d2-d88c12c690d3",
    defaultMessage: "Failed cosigner identity questions - Prepare Documents - Proof",
  },
  preMeetingCosignerIdentityQuestionsInfo: {
    id: "fda5948b-9045-4d49-939f-a4f14569fabe",
    defaultMessage: "Unable to generate cosigner identity questions - Prepare Documents - Proof",
  },
  preMeetingCosignerCredentialAnalysisIntro: {
    id: "0a875ac9-8aff-47bc-96f9-3d29946197f6",
    defaultMessage: "Capture cosigner's ID - Prepare Documents - Proof",
  },
  preMeetingCosignerCredentialAnalysisSecondary: {
    id: "063c9a2f-2cbd-431b-bd56-0041b3d70baf",
    defaultMessage: "Does cosigner require a secondary ID? - Prepare Documents - Proof",
  },
  preMeetingCosignerCredentialAnalysisSecondaryCapture: {
    id: "1da87db9-d8b6-4167-912b-7192a22a491a",
    defaultMessage: "Cosigner secondary ID verificaton - Prepare Documents - Proof",
  },
  preMeetingCosignerCredentialAnalysisFailure: {
    id: "a1187104-bb36-4c34-b664-050944dbcfab",
    defaultMessage: "Cosigner ID validation error - Prepare Documents - Proof",
  },
  preMeetingPersonalDetailsFailure: {
    id: "f7c613d9-d78b-4251-802c-ed79c9b0482a",
    defaultMessage: "Verification validation error - Personal Details - Proof",
  },
  guestMobileLink: {
    id: "f013b6a2-b258-41c6-ad66-bb72078e99e5",
    defaultMessage: "Continue in the App - Prepare Document - Proof",
  },
  requestNotaryVideoMeetingCameraRequired: {
    id: "22bd5cac-fc84-43f4-a159-f8c36d74c186",
    defaultMessage: "Camera access required - Prepare Documents - Proof",
  },
  requestNotaryVideoMeeting: {
    id: "9c2c951e-e8b6-4722-8e6c-a042f9cdf22b",
    defaultMessage: "Request Notary Video Meeting - Prepare Documents - Proof",
  },
  notaryVideoCallLobby: {
    id: "a13cd919-42f8-4661-af53-6344a618414c",
    defaultMessage:
      "Notary Video Call Lobby - Request Notary Video Meeting - Prepare Documents - Proof",
  },
  notaryVideoCallLobbyWaitingForOthers: {
    id: "d7d449da-a25c-4313-ad69-176bfd5767af",
    defaultMessage:
      "Waiting for a notary - Request Notary Video Meeting - Prepare Documents - Proof",
  },
  notaryVideoCallLobbyUnavailableNotary: {
    id: "06ee8bfb-ef58-47ad-84ec-1db2cc8f16c8",
    defaultMessage:
      "No available notaries - Request Notary Video Meeting - Prepare Documents - Proof",
  },
  notaryVideoCallLobbyPhoneCallback: {
    id: "ae973fdb-07fe-466e-82c5-94ca97fbd0bc",
    defaultMessage:
      "We apologize for the wait - Request Notary Video Meeting - Prepare Documents - Proof",
  },
  notaryVideoCallLobbyNsaUnavailable: {
    id: "dc458e0c-8442-44ad-b2db-4d5cefec636a",
    defaultMessage:
      "Outside Notary Signing Agent hours - Request Notary Video Meeting - Prepare Documents - Proof",
  },
  notaryVideoCallLobbyMeetingAccepted: {
    id: "405a0a07-c67f-47ab-a1cd-6b25663a021c",
    defaultMessage: "Meeting accepted - Request Notary Video Meeting - Prepare Documents - Proof",
  },
  notaryVideoCallLobbyPhoneNotification: {
    id: "3228f92e-ef8b-477c-85f6-31e80618e32a",
    defaultMessage: "Phone notification - Request Notary Video Meeting - Prepare Documents - Proof",
  },
  notaryOnboarding: {
    id: "9de520c4-6ad3-400e-816a-6fa717462ecf",
    defaultMessage: "Notary Onboarding - Proof",
  },
  notaryVideoCallLobbyLobbyError: {
    id: "4a7cf310-a84a-46ca-a710-cbc264a27214",
    defaultMessage: "Session cancelled - Request Notary Video Meeting - Prepare Documents - Proof",
  },
  signerIdentityExpiredSession: {
    id: "724afe01-70d7-43d3-b105-f46976cc2a04",
    defaultMessage: "Expired session - Prepare Documents - Proof",
  },
  errorBoundary: {
    id: "c4a4cec5-bbe0-42df-961f-1fd5b9efd17c",
    defaultMessage: "Something went wrong - Proof",
  },
  meeting: {
    id: "ed705488-d8a1-49e9-b4c0-9fd4b9793813",
    defaultMessage: "Meeting - Proof",
  },
  meetingCompletePaymentFailed: {
    id: "583f82db-2fad-41fe-8ac5-971228a7a325",
    defaultMessage: "Payment Failed - Proof",
  },
  meetingCompleteSetupPayment: {
    id: "433c372b-6049-452f-a3d3-7f320b46a636",
    defaultMessage: "Setup Payment - Notarization Complete - Proof",
  },
  meetingCompleteShareDocuments: {
    id: "8e34df5a-b7ba-4b96-b7e5-33f7a443e63a",
    defaultMessage: "Share Documents - Notarization Complete - Proof",
  },
  meetingCompleteCreatePassword: {
    id: "4e6efe43-2eda-44be-9591-a3e594899131",
    defaultMessage: "Create Password - Notarization Complete - Proof",
  },
  documentDetailsSummary: {
    id: "5ff0d6d7-f8b4-43ed-8d61-9e8217ca1095",
    defaultMessage: "Summary - Document Details - Proof",
  },
  documentDetailsNotary: {
    id: "58d3126d-f915-4521-bb43-9bb9ba28c088",
    defaultMessage: "Notary - Document Details - Proof",
  },
  documentDetailsVideo: {
    id: "a0a03cbf-eb9f-4c89-ba2b-575f7c63db5e",
    defaultMessage: "Video - Document Details - Proof",
  },
  documentDetailsDocument: {
    id: "2a6d39ef-9551-4bf0-8228-92b6c9f7789f",
    defaultMessage: "Document - Document Details - Proof",
  },
  shareDocumentByEmail: {
    id: "767fd03c-ed80-41ce-af45-a7c8daedd1f8",
    defaultMessage: "Share documents by email - Proof",
  },
  // Settings
  settingsDefault: {
    id: "11b6b461-0929-4014-8448-0b0ed10af24f",
    defaultMessage: "Settings - Proof",
  },
  settingsOrganizationOverview: {
    id: "4e20745b-c7c1-4481-adce-5ef715736a1d",
    defaultMessage: "Organization Overview - Settings - Proof",
  },
  settingsSharedInbox: {
    id: "524bac2b-d9a0-4d7a-8275-67f0c0d133fc",
    defaultMessage: "Shared inbox - Settings - Proof",
  },
  settingsEligibility: {
    id: "486e981a-9202-4dca-a7d2-81aaea80fcd9",
    defaultMessage: "Eligibility - Settings - Proof",
  },
  settingsBillingPlanDetails: {
    id: "7ba5c8c8-38b7-4a5e-a4c6-6c42c6d454a4",
    defaultMessage: "Plan Details - Billing - Settings - Proof",
  },
  settingsBillingPaymentSettings: {
    id: "fb138cc5-94d1-4bce-b85e-4092f76c603d",
    defaultMessage: "Payment Settings - Billing - Settings - Proof",
  },
  settingsBillingBillingContact: {
    id: "d3083695-7852-4749-9efa-26b8983053d5",
    defaultMessage: "Billing Contact - Billing - Settings - Proof",
  },
  settingsBillingRecentPayments: {
    id: "293a9e10-6e84-464f-aa24-fa4cd2d73a93",
    defaultMessage: "Recent Payments - Billing - Settings - Proof",
  },
  settingsTransactionsSignerSettings: {
    id: "c17ba4fc-b141-4f8f-aef9-54797fecabd1",
    defaultMessage: "Signer Settings - Transactions - Settings - Proof",
  },
  settingsTransactionsTemplateSettings: {
    id: "50fb4ade-7b09-411c-8902-723c5464e541",
    defaultMessage: "Template Settings - Transactions - Settings - Proof",
  },
  settingsTeamSecurity: {
    id: "47d539e6-2d3c-4fa3-a7ea-eb9f2e905890",
    defaultMessage: "Team security - Settings - Proof",
  },
  settingsBrandColors: {
    id: "8db17d53-bd8f-4f26-baf7-c0cbd6800d5b",
    defaultMessage: "Brand colors - Brand Customization - Settings - Proof",
  },
  settingsBrandLogoAndHeader: {
    id: "d5ad0361-8772-4c05-8c52-5d7ca8dbc125",
    defaultMessage: "Logo & header - Brand Customization - Settings - Proof",
  },
  settingsWebsiteCustomization: {
    id: "d3d147d0-f250-455f-b591-78a91bc7d6e5",
    defaultMessage: "Signer landing page - Website Customization - Settings - Proof",
  },
  settingsIntegrations: {
    id: "f221c2a1-dc96-4cbf-91b5-41ba69c5c85e",
    defaultMessage: "Integrations - Settings - Proof",
  },
  settingsApiKeys: {
    id: "4e220345-6359-4cb5-bb24-131e1900e16c",
    defaultMessage: "API keys - Settings - Proof",
  },
  settingsTransactionsNotaryInstructions: {
    id: "5295c7f3-e5ac-42e6-bec1-11c7bdab1ada",
    defaultMessage: "Notary Instructions - Transactions - Settings - Proof",
  },
  settingsProfilePrivacy: {
    id: "c47105b9-44dc-4123-bb9c-2d7c322eae7a",
    defaultMessage: "Privacy - Settings - Proof",
  },
  settingsProfileProofCertificate: {
    id: "c958960e-0a9d-4959-81c8-0c24e87bd9e7",
    defaultMessage: "Proof Card - Settings - Proof",
  },
  settingsProfileOverview: {
    id: "60dee205-c7d9-4cc7-b5da-587d4dc8b542",
    defaultMessage: "Profile Overview - Settings - Proof",
  },
  settingsProfilePersonalDetails: {
    id: "3dd2ef11-4d8f-43c3-8182-46ad8d4b69c6",
    defaultMessage: "Personal details - Profile - Settings - Proof",
  },
  settingsProfilePersonalPayments: {
    id: "dcf19966-afe7-4f1d-9c76-184cfddc24b0",
    defaultMessage: "Personal payment - Profile - Settings - Proof",
  },
  settingsProfilePersonalPaymentsUpdatePayment: {
    id: "569bda19-f3f2-48dc-bd6f-95910c4e71b1",
    defaultMessage: "Update payment method - Profile - Settings - Proof",
  },
  settingsProfilePersonalPaymentsPaymentDetails: {
    id: "51e0e92c-66f1-4d11-a812-7637f00625eb",
    defaultMessage: "Payment Detail - Profile - Settings - Proof",
  },
  settingsTerms: {
    id: "39e551be-118a-4a1c-996f-87267100ff96",
    defaultMessage: "Terms and conditions - Settings - Proof",
  },
  // Settings - Notary profile
  settingsNotaryDefault: {
    id: "6cb1d43c-32e7-421d-9438-31457faacacb",
    defaultMessage: "Notary profile - Settings - Proof",
  },
  settingsNotaryPersonalInfo: {
    id: "dc74d6a6-2c51-4b9d-b598-4db268c66ff9",
    defaultMessage: "Personal info - Notary profile - Settings - Proof",
  },
  settingsNotaryCommissionAndRonInfo: {
    id: "8c2d9e68-39ee-43e1-b5f3-147121e53269",
    defaultMessage: "Commission details - Notary profile - Settings - Proof",
  },
  settingsNotaryInsuranceDetails: {
    id: "d8a1e47f-0e2a-4918-bef7-2cc2e1aeb9e4",
    defaultMessage: "Insurance details - Notary profile - Settings - Proof",
  },
  settingsNotaryDigitalCertificate: {
    id: "2962e922-4189-4c66-9425-3744e58baaad",
    defaultMessage: "Digital certificate - Notary profile - Settings - Proof",
  },
  settingsNotarySignatureAndSeal: {
    id: "bd78f9ad-e274-47c2-b701-28243e52a6c3",
    defaultMessage: "Signature and seal - Notary profile - Settings - Proof",
  },
  settingsNotaryIdentityVerification: {
    id: "7b7f2cf8-687c-4b9f-96d7-6a66e44ffb33",
    defaultMessage: "Verify Identity - Notary profile - Settings - Proof",
  },
  settingsNotaryProofCertificate: {
    id: "f363641f-2b70-470d-8794-4c7b0d96d020",
    defaultMessage: "Proof Card - Notary profile - Settings - Proof",
  },
  settingsNotaryPayoutOptions: {
    id: "76f38801-12d3-402f-a1a5-3187bf55c59d",
    defaultMessage: "Payout options - Notary profile - Settings - Proof",
  },
  settingsNotaryStateEducation: {
    id: "3832ac1d-a3e9-49a9-af84-bcdc34c3b63f",
    defaultMessage: "State education - Notary profile - Settings - Proof",
  },
  settingsNotaryTermsOfUse: {
    id: "5e2e183f-73cc-4f82-b7ee-c33b4d57af08",
    defaultMessage: "Terms - Notary profile - Settings - Proof",
  },
  signingCompleteSetPassword: {
    id: "1e3f56ce-3b01-4452-b09d-99ef6b2af609",
    defaultMessage: "Set Password - Notarization Complete - Proof",
  },
  notaryJournal: {
    id: "370da221-0db8-4ed6-9d7d-df9309c6af7f",
    defaultMessage: "Notary journal - Proof",
  },
  // errors
  mutationError: {
    id: "53dc99b9-f125-4fef-bb49-1f3f030bd8f4",
    defaultMessage: "Something went wrong - Proof",
  },
} as const);

export function useDocumentTitle(portal: string): void {
  useEffect(() => {
    if (!portal) {
      return;
    }

    switch (portal) {
      case APPS.ADMIN:
        window.document.title = "Proof | Admin";
        break;
      case APPS.BUSINESS:
        window.document.title = "Proof | Business";
        break;
      case APPS.NOTARY:
        window.document.title = "Proof | Notary";
        break;
      case APPS.LENDER:
        window.document.title = "Proof for Lenders";
        break;
      case APPS.TITLE_AGENCY:
        window.document.title = "Proof for Title Agencies";
        break;
      case APPS.VERIFY:
        window.document.title = "Proof | Download a Completed Notarization";
        break;
      case APPS.CAPTURE:
      case APPS.CUSTOMER:
      default:
        window.document.title = "Proof";
        break;
    }
  }, [portal]);
}

export function useDocumentTitles() {
  return PROOF_DOCUMENT_TITLES;
}
