import { RiskScore } from "../common/risk_score";
import { FrameViewer } from "./frame_viewer";
import type { DeepfakeAnalysisMeeting as Meeting } from "../index.fragment.graphql";
import Styles from "./index.module.scss";

type Props = {
  meeting: Meeting;
};

export function DeepfakeAnalysisProcessed({ meeting }: Props) {
  const result = meeting.meetingAnalysis?.results[0];

  if (!result) {
    return null;
  }

  return (
    <div className={Styles.container}>
      <RiskScore result={result} />
      <FrameViewer result={result} />
    </div>
  );
}
