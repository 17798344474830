import { FormattedMessage } from "react-intl";

import { MeetingAnalysisRiskLevel } from "graphql_globals";
import { Badge } from "common/core/badge";
import { useDeepfakeAnalysisFeature, useDeepfakeAnalysisPermission } from "util/feature_detection";

export function RiskLevelBadge({
  riskLevel,
  withIcon = true,
  className,
}: {
  riskLevel: MeetingAnalysisRiskLevel | null;
  withIcon?: boolean;
  className?: string;
}) {
  switch (riskLevel) {
    case MeetingAnalysisRiskLevel.LOW_RISK:
      return (
        <Badge
          className={className}
          kind="success"
          withIcon={withIcon ? "success-filled" : undefined}
        >
          <FormattedMessage id="afe29ca6-fb1e-4a08-86cc-ce1cd439a0e4" defaultMessage="Not fake" />
        </Badge>
      );
    case MeetingAnalysisRiskLevel.LOW_QUALITY:
      return (
        <Badge className={className} kind="warning" withIcon={withIcon ? "warning" : undefined}>
          <FormattedMessage
            id="e2ae6f0f-70a3-4882-9a63-516df98342f0"
            defaultMessage="Low quality"
          />
        </Badge>
      );
    case MeetingAnalysisRiskLevel.HIGH_RISK:
      return (
        <Badge className={className} kind="danger" withIcon={withIcon ? "doc-warning" : undefined}>
          <FormattedMessage
            id="4a7bd7a1-0496-4446-acb3-cb7430276adc"
            defaultMessage="Fake detected"
          />
        </Badge>
      );
    default:
      return (
        <Badge className={className} kind="infoSubtle" withIcon={withIcon ? "expiry" : undefined}>
          <FormattedMessage id="ca109a73-a407-4983-814e-2a0839c1fc0a" defaultMessage="Processing" />
        </Badge>
      );
  }
}

export function MeetingRiskLevelBadge({
  meetingAnalysis,
  className,
}: {
  meetingAnalysis: {
    riskLevel: MeetingAnalysisRiskLevel | null;
  } | null;
  className?: string;
}) {
  const deepfakeAnalysisEnabled = useDeepfakeAnalysisFeature();
  const deepfakeAnalysisPermission = useDeepfakeAnalysisPermission();
  if (!deepfakeAnalysisEnabled || !deepfakeAnalysisPermission || !meetingAnalysis?.riskLevel) {
    return null;
  }
  return (
    <RiskLevelBadge className={className} riskLevel={meetingAnalysis.riskLevel} withIcon={false} />
  );
}
