import { type ComponentProps, type ReactNode } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { isPast } from "date-fns";

import { isMobileDevice } from "util/support";
import Button from "common/core/button";
import { Avatar } from "common/core/avatar";
import { Heading, Paragraph } from "common/core/typography";
import { useMobileScreenClass } from "common/core/responsive";
import { NotarizeNetwork } from "common/core/logo/notarize_network";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import CertificateCard from "common/notary/certificate/card";
import GreenCheckIcon from "common/notary/certificate/green_check_icon";
import { MenuItemWrapper } from "common/core/popout_menu/common";
import LoadingIndicator from "common/core/loading_indicator";
import { abbreviatedUserName } from "common/user/abbreviated_user_name";
import { useFeatureFlag } from "common/feature_gating";
import CertificateQuery from "common/settingsv2/sidebar_settings/profile/proof_certificate/certificate/certificate_query.graphql";
import { CONSUMER_CERTIFICATES } from "constants/feature_gates";
import { useQuery } from "util/graphql";
import { usePeerReferrals } from "util/feature_detection";

import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  widgetTitle: {
    id: "9fb9fd73-f0aa-415f-8954-cc30a8574094",
    defaultMessage: "My Proof account",
  },
  proofIDTitle: {
    id: "d1145772-c965-4dc3-84ce-a59b24accbb1",
    defaultMessage: "My Proof Card",
  },
  signDocumentLabel: {
    id: "f750a7cb-7cff-4644-b279-1c9c90abb220",
    defaultMessage: "Sign a document",
  },
  manageAccountLabel: {
    id: "bf518b36-f486-40a6-b4a9-7883bbb093d0",
    defaultMessage: "Manage my account",
  },
  viewNotaryJournalLabel: {
    id: "a2a3ac27-1e14-4764-8699-4b17df0f2e03",
    defaultMessage: "View notary journal",
  },
  logOutLabel: {
    id: "2f495866-3f80-437e-a367-3156911238dd",
    defaultMessage: "Log out",
  },
  managedBy: {
    id: "0cfe4c43-9371-4cd0-96b4-c6edaf5b75c1",
    defaultMessage: "Managed by {domain}",
  },
  referAFriendLabel: {
    id: "952b23dc-5c05-4229-9c0f-cd4925c36794",
    defaultMessage: "Refer a friend! Give $5, get $5.",
  },
});

type AccountInfoProps = {
  userFullName: string | undefined;
  userInitials: string | undefined;
  userDomain: string | undefined;
};

type ProofIdListProps = {
  closeMenu?: () => void;
  isMenu?: boolean;
  hasNotaryProfile: boolean;
  hasSignAccess: boolean;
  signDocumentsUrl?: () => void;
  notaryJournalUrl?: () => void;
  handleSettingsUrl: () => void;
  handleLogout?: () => void;
  showReferAFriend?: boolean;
};

export function AccountBlurb(props: { size?: ComponentProps<typeof Paragraph>["size"] }) {
  return (
    <>
      <Paragraph size={props.size}>
        <FormattedMessage
          id="ed4975d7-f14e-4a42-a9f2-36e3c5ef5863"
          defaultMessage="One account for everything you do with Proof."
        />
      </Paragraph>
      <Paragraph size={props.size}>
        <FormattedMessage
          id="ed4975d7-f14e-4a42-a9f2-36e3c5ef5863"
          defaultMessage="Sign, verify, or connect with the {notarizeNetwork}."
          values={{ notarizeNetwork: <NotarizeNetwork /> }}
        />
      </Paragraph>
    </>
  );
}

export function AccountInfo({ userInitials, userFullName, userDomain }: AccountInfoProps) {
  const intl = useIntl();
  const isMobileSize = useMobileScreenClass();
  const userCertDisplayEnabled = useFeatureFlag(CONSUMER_CERTIFICATES);
  const { data, loading } = useQuery(CertificateQuery);

  if (loading || !data?.viewer.user) {
    return <LoadingIndicator />;
  }

  const user = data.viewer.user;
  const certificate = user.certificate;

  let isCertificateValid, certificateSubject;
  if (certificate) {
    isCertificateValid = !(isPast(certificate.validTo) || Boolean(certificate.revokedAt));
    certificateSubject = certificate.subject.split("=").pop();
  }

  return (
    <div className={Styles.accountInfo}>
      {userDomain && (
        <Paragraph size="small" textColor="subtle" className={Styles.managedBy}>
          {intl.formatMessage(MESSAGES.managedBy, { domain: userDomain })}
        </Paragraph>
      )}
      <div className={Styles.accountName}>
        <div>
          <Heading level="h2" textStyle="headingFive">
            {userCertDisplayEnabled && certificate
              ? intl.formatMessage(MESSAGES.proofIDTitle)
              : intl.formatMessage(MESSAGES.widgetTitle)}
          </Heading>
          <div className={Styles.userName}>
            {userFullName && (
              <Paragraph size="large">
                {userCertDisplayEnabled && certificate
                  ? abbreviatedUserName(
                      user as { firstName: string; middleName?: string; lastName: string },
                      24,
                    )
                  : userFullName}
              </Paragraph>
            )}
            {userCertDisplayEnabled && isCertificateValid && (
              <div>
                <GreenCheckIcon className={Styles.icon} />
              </div>
            )}
          </div>
        </div>
        <span aria-hidden="true">
          <Avatar
            size={isMobileSize ? "medium" : "large"}
            userInitials={userInitials}
            kind="green"
          />
        </span>
      </div>
      {userCertDisplayEnabled && certificate && (
        <div className={Styles.certCardContainer}>
          <Link to="/settings/profile/proof-certificate">
            <CertificateCard
              user={{ firstName: certificateSubject }}
              disabled={!isCertificateValid}
              clickable
            />
          </Link>
        </div>
      )}
      <AccountBlurb size="small" />
    </div>
  );
}

function ProofIdListItem({
  children,
  isMenu,
  className,
}: {
  children: ReactNode;
  isMenu?: boolean;
  className?: string;
}) {
  return isMenu ? (
    <MenuItemWrapper removeInteractionState>{children}</MenuItemWrapper>
  ) : (
    <li className={className}>{children}</li>
  );
}

export function ProofIdList({
  closeMenu,
  hasNotaryProfile,
  hasSignAccess,
  signDocumentsUrl,
  notaryJournalUrl,
  handleSettingsUrl,
  handleLogout,
  isMenu = true,
  showReferAFriend = false,
}: ProofIdListProps) {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const peerReferralsEnabled = usePeerReferrals();
  const navigate = useNavigate();

  function peerReferralPath() {
    const filterParam = searchParams.get("filter") || "all";
    const pageNumber = Number(searchParams.get("page") || "1");
    return `/bundle/records?peer_referral=true&filter=${filterParam}&page=${pageNumber}`;
  }

  function handleClick(onClick: () => void) {
    if (isMenu && closeMenu) {
      onClick();
      closeMenu();
    }
    onClick();
  }

  const proofIdListItems = (
    <>
      {hasSignAccess && signDocumentsUrl && (
        <ProofIdListItem isMenu={isMenu}>
          <ButtonStyledLink
            buttonColor="action"
            variant="primary"
            onClick={() => handleClick(signDocumentsUrl)}
            fullwidth
            openInCurrentTab
            withIcon={{ name: "signature", placement: "left" }}
          >
            {intl.formatMessage(MESSAGES.signDocumentLabel)}
          </ButtonStyledLink>
        </ProofIdListItem>
      )}

      <ProofIdListItem isMenu={isMenu}>
        <ButtonStyledLink
          onClick={() => handleClick(handleSettingsUrl)}
          buttonColor="action"
          variant="secondary"
          fullwidth
          openInCurrentTab
          withIcon={{ name: "name", placement: "left" }}
        >
          {intl.formatMessage(MESSAGES.manageAccountLabel)}
        </ButtonStyledLink>
      </ProofIdListItem>

      {hasNotaryProfile && !isMobileDevice() && notaryJournalUrl && (
        <ProofIdListItem isMenu={isMenu}>
          <ButtonStyledLink
            onClick={() => handleClick(notaryJournalUrl)}
            buttonColor="action"
            variant="tertiary"
            fullwidth
            openInCurrentTab
            withIcon={{ name: "notary-journal", placement: "left" }}
          >
            {intl.formatMessage(MESSAGES.viewNotaryJournalLabel)}
          </ButtonStyledLink>
        </ProofIdListItem>
      )}

      {peerReferralsEnabled && showReferAFriend && isMobileDevice() && (
        <ProofIdListItem isMenu={isMenu}>
          <ButtonStyledLink
            onClick={() => handleClick(() => navigate(peerReferralPath()))}
            data-automation-id="refer-a-friend-widget"
            fullwidth
            variant="tertiary"
            withIcon={{ name: "employees-filled", placement: "left" }}
          >
            {intl.formatMessage(MESSAGES.referAFriendLabel)}
          </ButtonStyledLink>
        </ProofIdListItem>
      )}

      {handleLogout && (
        <ProofIdListItem isMenu={isMenu}>
          <Button
            automationId="log-out-button"
            onClick={handleLogout}
            variant="tertiary"
            buttonColor="dark"
            fullwidth
          >
            {intl.formatMessage(MESSAGES.logOutLabel)}
          </Button>
        </ProofIdListItem>
      )}
    </>
  );

  return isMenu ? <>{proofIdListItems}</> : <ul>{proofIdListItems}</ul>;
}
