import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { Heading, Paragraph } from "common/core/typography";
import { CircuitIllustration } from "common/core/illustrations/circuit";
import StopwatchLeft from "assets/images/signer/stopwatch-left.svg";

import { RiskScore } from "../common/risk_score";
import CommonStyles from "../common/index.module.scss";

const MESSAGES = defineMessages({
  illustrationAlt: {
    id: "5cef036c-3629-4dd2-a02f-9ad722b995a9",
    defaultMessage: "Stopwatch",
  },
});

function DeepfakeAnalysisProcessingRiskScore() {
  return <RiskScore result={null} />;
}

function DeepfakeAnalysisProcessingBody() {
  const intl = useIntl();
  return (
    <div className={CommonStyles.bodyContainer}>
      <img src={StopwatchLeft} alt={intl.formatMessage(MESSAGES.illustrationAlt)} width={145} />

      <Heading textStyle="headingFour" level="h2">
        <FormattedMessage
          id="1f8526e8-c621-4f83-a9ef-fec13d694731"
          defaultMessage="Deepfake analysis pending"
        />
      </Heading>
      <Paragraph textColor="subtle">
        <FormattedMessage
          id="b96ea907-6996-4ee4-b240-0ae887c78f5e"
          defaultMessage="The video analysis is processing and will be available to view soon"
        />
      </Paragraph>
      <CircuitIllustration />
    </div>
  );
}

export function DeepfakeAnalysisProcessing() {
  return (
    <div className={CommonStyles.container}>
      <DeepfakeAnalysisProcessingRiskScore />
      <DeepfakeAnalysisProcessingBody />
    </div>
  );
}
